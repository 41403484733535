<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
		 @clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.order_no" size="small" clearable placeholder="按订单号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.fans_id" size="small" style="width: 150px;" clearable placeholder="按占位时间"></el-input>
			</el-form-item>
			<el-form-item slot="search-item" >
				<el-input v-model="searchFormData.phone" size="small" style="width: 150px;" clearable placeholder="按抢编号" ></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_no" size="small" style="width: 150px;" clearable placeholder="按站点名称"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.status" placeholder="按占位状态查询" clearable size="small">
					<el-option label="占位中" :value="10"></el-option>
					<el-option label="占位结束" :value="20"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.pay_status" placeholder="按支付状态查询" clearable size="small">
					<el-option v-for="(item, key) in types.pay_status" :label="item" :value="key" :key="key"></el-option>
					<!--el-option label="未支付" :value="10"></el-option>
					<el-option label="已支付" :value="20"></el-option-->
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="sit_no" title="订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="device_no" title="枪编号" align="center" min-width="100px"></vxe-table-column>
			<vxe-table-column slot="table-item" field="group_name" title="站点名" align="center" min-width="100px">

			</vxe-table-column>
			<vxe-table-column slot="table-item" field="duration" title="占位时长" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{$common.secondToTime(row.duration)}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="jf" title="计费时长" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{$common.secondToTime(row.jf)}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="free_duration" title="免费时长" align="center" min-width="100px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="pay_price" title="计费金额" align="center" min-width="100px">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="pay_status" title="付款状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.pay_status[row.pay_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
<!--			<vxe-table-column slot="table-item" field="after_balance" title="账户余额" align="center" min-width="80px" />-->
			<vxe-table-column slot="table-item" field="create_time" title="占位开始时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="end_time" title="占位结束时间" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="占位状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{row.status==10?"占位中":'占位结束'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="orde_status" title="退款状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.order_status[row.order_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="230">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detailOne(row)" v-if="$hasAccess('user.order/view')">充电订单</el-button>
					<el-button size="small" plain @click="refundOne(row)" v-if="row.pay_status==20&&$hasAccess('user.seatorder/refund')&&row.pay_price>0">退款</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 详情 -->
		<el-dialog title="消费详情" :visible.sync="detailDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="1200px">
			<el-form ref="detailForm" label-width="140px" class="form" size="mini">
				<el-row>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:1}">
						<!-- 订单信息 -->
						<div class="el-block">
							<div class="row-title">订单信息</div>
							<el-form-item label="订单号：">{{detailDialogFormData.order_no}}</el-form-item>
							<el-form-item label="订单状态：">{{types.order_status[detailDialogFormData.order_status]||'未知状态'}}</el-form-item>
							<el-form-item label="智能充电订单：">{{types.is_auto[detailDialogFormData.is_auto]||'未知'}}</el-form-item>
							<el-form-item label="订单金额：">{{detailDialogFormData.order_price}}元</el-form-item>
							<!-- <el-form-item label="下单时间：">{{detailDialogFormData.creat_time}}</el-form-item> -->
							<el-form-item label="订单时间：">{{detailDialogFormData.start_time}} -<br />{{detailDialogFormData.end_time}}</el-form-item>
							<el-form-item label="订单时长：">{{$common.secondToTime(detailDialogFormData.order_duration*60)}}</el-form-item>
							<el-form-item label="订单功率：">{{detailDialogFormData.order_power}}</el-form-item>
							<el-form-item label="实际充电平均功率：">{{detailDialogFormData.power}}</el-form-item>
							<el-form-item label="实际用电量：">{{detailDialogFormData.electric}}</el-form-item>
							<el-form-item label="实际时长：">{{$common.secondToTime(detailDialogFormData.duration)}}</el-form-item>
							<el-form-item label="账户余额：">{{detailDialogUserBalance}}元</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:2}">
						<!-- 支付信息 -->
						<div class="el-block">
							<div class="row-title">支付信息</div>
							<el-form-item label="消费用户：">{{detailDialogFormData.u_type==10?'微信-'+(detailDialogFormData.nickname||'匿名用户'):'电卡-'+detailDialogFormData.cardno}}</el-form-item>
							<el-form-item label="支付状态：">{{types.pay_status[detailDialogFormData.pay_status]||'未知状态'}}</el-form-item>
							<el-form-item label="支付类型：">{{types.pay_type[detailDialogFormData.pay_type] + (detailDialogFormData.cardno ? '-在线卡' : '')||'未知类型'}}</el-form-item>
							<el-form-item v-if="detailDialogFormData.cardno" label="刷卡卡号：">{{detailDialogFormData.cardno}}</el-form-item>
							<el-form-item label="实际支付金额：">{{detailDialogFormData.pay_price}}元</el-form-item>
							<el-form-item label="微信支付金额：">{{detailDialogFormData.wx_price}}元</el-form-item>
							<el-form-item label="支付时间：">{{detailDialogFormData.pay_time}}</el-form-item>
						</div>
						<!-- 设备信息 -->
						<div class="el-block">
							<div class="row-title">设备信息</div>
							<el-form-item label="设备名称：">{{detailDialogFormData.device_name}}<br />{{detailDialogFormData.device_no}}</el-form-item>
							<el-form-item label="插座序号：">{{detailDialogFormData.plug_num}}（ID：{{detailDialogFormData.plug_id}}）</el-form-item>
							<el-form-item label="异常原因：">{{types.unusual_status[detailDialogFormData.unusual_status]||'未知原因'}}</el-form-item>
						</div>
					</el-col>
					<el-col :xs="{span:24,offset:0}" :sm="{span:6,offset:2}">
						<!-- 优惠信息 -->
						<div class="el-block">
							<div class="row-title">优惠信息</div>
							<el-form-item label="消费优惠ID：">{{detailDialogFormData.gift_num?detailDialogFormData.gift_id:'无'}}</el-form-item>
							<el-form-item label="优惠数量：">{{detailDialogFormData.gift_num||0}}</el-form-item>
						</div>
						<!-- 代理信息 -->
						<div class="el-block">
							<div class="row-title">代理信息</div>
							<el-form-item label="代理商ID：">{{detailDialogFormData.agent_id||'无'}}</el-form-item>
							<el-form-item label="分销金额：">{{detailDialogFormData.share_price}}元</el-form-item>
							<el-form-item label="分销结算状态：">{{types.share_status[detailDialogFormData.share_status]||'未知状态'}}</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'user-seatorder-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '消费列表',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {
					u_type: 10
				},
				types: {
					pay_status: {
						10: '未支付',
						20: '已支付',
						30: '退款',
						50: '无需支付',
						60: '已免收',
					},
					pay_type: {
						10: '钱包',
						20: '电卡',
						30: '先付后充',
						40: '赠送金',
						50:'信用分'
					},
					order_status: {
						10: '进行中',
						20: '已取消',
						30: '已完成'
					},
					unusual_status: {
						10: '正常',
						20: '功率异常',
						30: '插座脱落',
						40: '信号异常'
					},
					is_auto: {
						10: '是',
						20: '否'
					},
					share_status: {
						10: '不结算',
						20: '未结算',
						30: '已结算'
					}
				},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: [],
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
				detailDialogUserBalance: 0,
				// 日志
				workDialogShow: false,
				workDialogFormData: {},
				// 退款
				refundDialogShow: false,
				refundDialogFormData: {},
				refundDialogFormOriData: {},
				refundRules: {
					refund_price: [{
						required: true,
						message: '请填写退款金额',
						trigger: 'change'
					}],
					refund_for: [{
						required: true,
						message: '请选择退款方式',
						trigger: 'change'
					}]
				}
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				// 小区限制
				const group_id = this.$store.state.user.userInfo.group_id
				if (!searchData.group_id && group_id) {
					searchData.group_id = group_id
				}
				const res = await this.$api.Fans.GetSeatOrderList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {
					u_type: 10
				}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
			// 详情
			async detailOne(row) {
				const params = {
					token: this.$store.state.user.token,
					order_id: row.order_id
				}
				const res = await this.$api.Fans.GetOrderDetail(params)
				this.detailDialogFormData = res
				this.detailUserOne(res.uid)
				this.detailDialogShow = true
			},
			// 用户详情
			async detailUserOne(id) {
				const params = {
					token: this.$store.state.user.token,
					fans_id: id
				}
				const res = await this.$api.Fans.GetFansDetail(params)
				this.detailDialogUserBalance = res.balance
			},
			// 列表格式化
			formatType({
				cellValue
			}) {
				if (cellValue == 'server') {
					return '服务器 => 硬件'
				}
				if (cellValue == 'client') {
					return '硬件 => 服务器'
				}
				return '未知'
			},
			// 退款
			refundOne(row) {
				this.$confirm('确定要退款吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						sit_id: row.sit_id,
						uid:row.uid,
						pay_type: row.pay_type,
						pay_price:row.pay_price
					}
					await this.$api.Fans.RefundSeatOrder(params);
					this.$notify({
						title: '成功',
						message: '退款成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 用户名查询
			async remoteFans(query) {
				if (query !== '') {
					this.sLoading = true
					const params = {
						token: this.$store.state.user.token
					}
					const searchData = {
						nickname: query
					}
					const res = await this.$api.Fans.GetFansList(params, searchData)
					this.sLoading = false
					this.sFans = res.data
				} else {
					this.sFans = []
				}
			},
			// 设备名查询
			async remoteDevice(query) {
				if (query !== '') {
					this.s2Loading = true
					const params = {
						token: this.$store.state.user.token
					}
					const searchData = {
						device_name: query
					}
					const res = await this.$api.Device.GetDeviceList(params, searchData)
					this.s2Loading = false
					this.sDevice = res.data
				} else {
					this.sDevice = []
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
